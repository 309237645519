@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css";
@font-face {
  font-family: "Roboto";
  src: url("../public/fonts/Roboto-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Noto Sans JP";
  src: url("../public/fonts/NotoSansJP-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Futura";
  src: url("../public/fonts/unicode.futurab.ttf") format("truetype");
}
@font-face {
  font-family: "Futura-Bold";
  src: url("../public/fonts/Futura\ Heavy\ font.ttf") format("truetype");
}
body {
  margin: 0;
  font-family: 'Noto Sans JP', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  color: #615E5E;
  font-size: 14px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
p {
  margin: 0 !important;
}
select {
  /* border-radius: 5px; */
}
/* text size */
.font12 {
  font-size: 12px;
}
.font14 {
  font-size: 14px;
}
.font16 {
  font-size: 16px;
}
.font18 {
  font-size: 18px;
}
.font20 {
  font-size: 20px;
}
.font24 {
  font-size: 24px;
}
.font28 {
  font-size: 28px;
}
.font-bold {
  font-weight: bold;
}
.font-weight-400 {
  font-weight: 400;

}
.text-underline {
  text-decoration: underline;
}
.no-wrap {
  white-space: nowrap;
}
.bg-gray {
  background-color: #f2f9fb;
}
.bg-primary {
  background-color: #26a69a;
}
.bg-secondary {
  background-color: #bdbdbd;
}
.bg-error {
  background-color: #d84315;
}
.color-gray {
  color: #777777;
}
.color-primary {
  color: rgb(55,187,247);
}
.color-secondary {
  color: #bdbdbd;
}
.color-white {
  color: white
}
.color-error {
  color: #d84315;
}
.w-250 {
  width: 250px;
}
.w-400 {
  width: 450px;
}
.full-width {
  width:100%
}
.cursor-pointer {
  cursor: pointer;
}
.my-container {
  margin: 30px 60px;
}
.top-header {
  width: 100%;
  height: 70px;
  background-color: white;
  box-shadow: 2px 0px 5px 4px #dadada; 
}
.top-header-title {
  line-height: 70px;
}
.top-header-name {
  line-height: 70px;
  font-size: 20px;
  font-weight: 400;
}
.top-header-avatar {
  height: 50px;
  width: 50px;
}
.user-form {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30px;
}
.submit-btn {
  margin: 0px 10px !important;
  width: 150px;
}
.cancel-btn {
  margin: 0px 10px !important;
  width: 150px;
}
.error {
  color: red;
  font-size: 14px;
}
.field-label {
  font-weight: bold;
  font-size: 16px;
}
.input-field {
  padding-left: 10px;
  border: 1px solid gray;
  width: 100%;
  font-size: 18px;
  line-height: 40px;
}

.input-field-sm {
  height: 35px;
  padding-left: 10px;
  border: 1px solid gray;
  width: 100%;
}
.input-label {
  line-height: 40px;
  width: 150px;
  min-width: 150px;
  font-size: 18px;
}
.input-select {
  width: 100%;
  border: 1px solid gray;
  /* border-radius: 5px; */
  height: 50px;
  font-size: 18px;
  line-height: 50px;
  padding-left: 5px;
}
.input-select-sm {
  width: 100%;
  height: 35px;
  line-height: 35px;
  border: 1px solid gray;
  padding-left: 5px;
}
.condition-box {
  background-color: rgb(229 237 251);
  padding: 10px 15px;
}
.squre-button {
  background-color: whitesmoke !important;
  border-radius: 10px !important;
  border: 2px solid !important;
  padding: 0px !important;
  width: 50px !important;
  min-width: 0px !important;
  height: 45px !important;
}
.no-border-text {
  border: none !important;
  background-color: transparent;
}
.detail-card {
  border: 2px gray solid;
  border-radius: 8px;
}
input {
  /* border-radius: 5px; */
  padding: 4px;
}
/* power css */
.MuiPopover-paper {
  box-shadow: 2px 4px 4px -3px gray !important;
}
.MuiDataGrid-cell {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.MuiInputBase-input{
  padding: 7px 10px !important;
}
.MuiSelect-select{
  padding: 8px 24px 8px 8px !important;
}
.MuiLoadingButton-loadingIndicatorEnd {
  position: relative !important;
  margin-left: 30px;
  color: white;
}
.cc-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
}
.proz-title {
  width: 450px;
}
.cc-body {
  width: 100%;
  background: white;
  padding: 20px;
  box-shadow: 1px 10px 20px 1px #ddd;
}
.login-form {
  width: 400px;
}
.eye-position {
  margin-left: -40px;
}
.disabled {
  user-select: none;
  opacity: 30%;
}
.number-tag {
  white-space: nowrap;
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
  align-items: center;
  margin-right: 15px;
  background-color: rgb(55,187,247);
  padding: 4px 10px;
  border-radius: 15px;
  margin-bottom: 5px;
  color: black;
}
.number-tag-close {
  /* background-color: #777777; */
  border-radius: 9px;
  line-height: 18px;
  width: 18px;
  height: 18px;
  padding-left: 4px;
  color: white;
  cursor: pointer;
}
.card-entry {
  display: grid;
  grid-template-columns: 150px auto;
  align-items: center;
  font-size: 20px;
  margin: 20px 0px;
}